import React from "react";

export default function JobHistoryContent () {
  return (
    <>
      <div className='p-jobHistory'>
        <section className='p-jobHistory__section'>
          <h2 className='c-jobHistory__companyName'>株式会社アクトビ</h2>
          <dl className='c-jobHistory__definitionList'>
            <dt className='c-jobHistory__definitionTerm'>在籍期間：</dt>
            <dd className='c-jobHistory__definitionDescription'>2023年12月〜現在</dd>
          </dl>
          <div className='c-jobHistory__overflow'>
            <table className='c-jobHistory__table'>
              <tr>
                <th>クライアント名・概要</th>
                <th>人数・開発期間</th>
                <th>使用技術・ツール</th>
                <th>担当箇所</th>
              </tr>
              <tr>
                <td>NTTデータ関西さま<span>（エンドユーザー：三菱ロジスティクスさま）</span><br />Salesforce Experience Cloud フロント実装</td>
                <td>デザイナー：2名<br />
                  エンジニア：2名<br />
                  Salesforce：3名<br /><br />
                  2024年10月上旬〜現在
                </td>
                <td>Vue.js, Experience Cloud</td>
                <td>全ページ（予定）</td>
              </tr>
              <tr>
                <td>株式会社キャリカレさま<br />「資格のキャリカレ for Biz」<br />to B向けの通信教育講座・資格のシステム開発</td>
                <td>エンジニア：5名<span>（うち他社の方が2名）</span><br />デザイナー：1名<br /><br />2024年9月〜現在</td>
                <td>Vue.js, Laravel, Figma</td>
                <td>ユーザー画面：カリキュラム詳細、講座詳細、動画再生画面<br />
                  管理画面：お知らせ（一覧・修正・詳細）
                </td>
              </tr>
              <tr>
                <td>株式会社キャリカレさま<br />「資格のキャリカレ」<br />to C向けの通信教育講座・資格のシステム開発</td>
                <td>エンジニア：7名<span>（うち他社の方が2名）</span><br />デザイナー：1名<br /><br />2023年12月〜2024年7月</td>
                <td>Vue.js, Laravel, Figma</td>
                <td>ユーザー画面：よくあるご質問、<br />
                  管理画面：お知らせ（一覧・修正・詳細）
                </td>
              </tr>
              <tr>
                <td>電通総研様<span>（エンドユーザー：各企業さま）</span><br />従業員のエンゲージメント向上を目的としたTeamsアプリの機能改善</td>
                <td>エンジニア：2名
                <br />デザイナー：1名<br />2024年9月〜現在</td>
                <td>Vue.js</td>
                <td>ユーザー画面全般、管理画面全般</td>
              </tr>
              <tr>
                <td>スープストックトーキョーさま<br />コーポレートサイト・ブランドサイトの保守</td>
                <td>エンジニア：1〜2名<br />2024年1月〜現在</td>
                <td>HTML5/CSS3, JavaScript, WordPress</td>
                <td>WordPress改修、新規ページ作成、軽微な修正</td>
              </tr>
              <tr>
                <td>株式会社サンブリッジさま<br />コーポレートサイトのリニューアル</td>
                <td>エンジニア：1名<br />2024年5月〜2024年6月</td>
                <td>HTML5/CSS3, JavaScript, WordPress</td>
                <td>共通パーツ、お知らせ一覧・詳細、静的ページ</td>
              </tr>
              <tr>
                <td>電通総研さま<br />（エンドクライアント：埼玉県ときがわ町さま）<br />「堂平天文台デジタルミュージアム」</td>
                <td>エンジニア：2名<br />2024年3月〜2024年6月</td>
                <td>Next.js</td>
                <td>トップ、詳細画面（全2ページ）</td>
              </tr>
              <tr>
                <td>ワイエスフード株式会社さま<br />コーポレートサイトのリニューアル</td>
                <td>エンジニア：1名<br />2023年12月〜2024年3月</td>
                <td>HTML5/CSS3, JavaScript, WordPress</td>
                <td>全ページ</td>
              </tr>
              <tr>
                <td>泉友株式会社さま<br />福利厚生サービスなどを提供するサイトのシステム開発</td>
                <td>エンジニア：7名<span>（うちフリーランスの方が2名）</span><br />2023年12月〜2024年2月</td>
                <td>Vue.js, Laravel</td>
                <td>ユーザー画面：下層ページ<br />
                  管理画面<br />軽微なバグ修正
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section className='p-jobHistory__section'>
          <h2 className='c-jobHistory__companyName'>株式会社サイドスリー</h2>
          <dl className='c-jobHistory__definitionList'>
            <dt className='c-jobHistory__definitionTerm'>在籍期間：</dt>
            <dd className='c-jobHistory__definitionDescription'>2020年12月〜2023年11月</dd>
          </dl>
          <div className='c-jobHistory__overflow'>
            <table className='c-jobHistory__table'>
              <tr>
                <th>業務内容</th>
                <th>使用技術・ツール</th>
              </tr>
              <tr>
                <td>
                  <p className='c-jobHistory__text02'>携わった案件が多いため、内容を省略して記載しております。</p>
                  <ul className='c-jobHistory__list'>
                    <li className='c-jobHistory__item'>中～大規模の企業サイトのコーディングとCMS組み込み</li>
                    <li className='c-jobHistory__item'>Instagram広告バナーのデザイン作成（Photoshop）</li>
                    <li className='c-jobHistory__item'>新人コーダーの教育、新卒社員研修</li>
                    <li className='c-jobHistory__item'>CMS設計書作成</li>
                  </ul>

                  <p className='c-jobHistory__text02'>自身が携わった主なクライアント名</p>
                  <ul className='c-jobHistory__list'>
                    <li className='c-jobHistory__item'>コニカミノルタさま<span>（エンドユーザー：アズビル株式会社さま、artience株式会社さま）</span></li>
                    <li className='c-jobHistory__item'>株式会社アシスト（Ashisuto）さま</li>
                    <li className='c-jobHistory__item'>株式会社ニップン（NIPPN）さま</li>
                    <li className='c-jobHistory__item'>株式会社ジークス（ZYYX）さま<span>（エンドユーザー：キンライサーさま）</span></li>
                    <li className='c-jobHistory__item'>株式会社こうべ未来都市機構さま<span>（ショッピンセンター「セリオ」）</span></li>
                    <li className='c-jobHistory__item'>Amtteliebe（アンテリーベ）さま</li>
                    <li className='c-jobHistory__item'>株式会社クラレさま<span>（製品ブランディングサイト「アーネストン」）</span></li>
                    <li className='c-jobHistory__item'>倉敷紡績株式会社<span>（クラボウ バイオメディカル部）</span></li>
                    <li className='c-jobHistory__item'>関西大学さま</li>
                  </ul>
                </td>
                <td className='c-jobHistory__tdFlex'>
                  <p className='c-jobHistory__text01'>
                    HTML5 / CSS3, JavaScript, jQuery, Gulp, Node.js, Pug<br />
                    Adobe（Photoshop, Illustrator , XD）, Figma<br />
                    CMS（MovableType, PowerCMS, PowerCMS X, NOREN, WordPress）
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </section>

        <section className='p-jobHistory__section'>
          <h2 className='c-jobHistory__companyName'>業務委託</h2>
          <p className='c-jobHistory__text02'>エンジニア勉強会で知り合った仲間と開発を行いました。</p>
          <div className='c-jobHistory__overflow'>
            <table className='c-jobHistory__table'>
              <tr>
                <th>クライアント名・概要</th>
                <th>人数・開発期間</th>
                <th>使用技術・ツール</th>
                <th>担当箇所</th>
              </tr>
              <tr>
                <td>コムネット株式会社さま<br />商品在庫管理システムの開発</td>
                <td>5名<br />2022年10月-2023年2月</td>
                <td>React.js, TypeScript, Chakra UI, Figma</td>
                <td>商品一覧、商品修正、得意先一覧の作成</td>
              </tr>
              <tr>
                <td>三菱地所設計さま<br />設備・作業登録システムの開発</td>
                <td>7名<br />2022年6月〜2022年8月</td>
                <td>React.js, TypeScript, Chakra UI<br /><span>はじめて業務でReactを触りました。</span></td>
                <td>トップ画面、作業登録画面の作成</td>
              </tr>
            </table>
          </div>
        </section>

        <section className='p-jobHistory__section'>
          <h2 className='c-jobHistory__companyName'>業務委託</h2>
          <div className='c-jobHistory__overflow'>
            <table className='c-jobHistory__table'>
              <tr>
                <th>クライアント名・概要</th>
                <th>人数・開発期間</th>
                <th>使用技術・ツール</th>
                <th>担当箇所</th>
              </tr>
              <tr>
                <td>株式会社Digeonさま<br />コーポレートサイトのリニューアル</td>
                <td>6名<br />2023年7月〜2023年8月</td>
                <td>React.js, TypeScript, TailwindCSS, Figma</td>
                <td>下層ページの作成</td>
              </tr>
              <tr>
                <td>株式会社Mediestさま<br />医療従事者向けシフトアプリのデザイン</td>
                <td>5名<br />2023年7月〜2023年8月</td>
                <td>Figma</td>
                <td>デザイン・コンポーネント作成（全ページ）<br />お客様との打ち合わせ</td>
              </tr>
              <tr>
                <td>株式会社Wellさま<br />求人サイトのシステム開発</td>
                <td>5名<br />2023年9月</td>
                <td>React.js, TypeScript, TailwindCSS, Figma</td>
                <td>下層ページの作成</td>
              </tr>
            </table>
          </div>
        </section>

      </div>
    </>
  )
}